// Style the body in the App component
// because semantic ui overtakes the styles in src/styles/index.scss
body {
  //font-family: 'neon' sans-serif;
  background-color:#0f1435;//rgb(180, 250, 255);//#154D62;//#28292c; //#B5E51C #2A2B2A
  //width: 1920px;
  //height: 1080px;
  height:100%;
  width:100%;
  margin:0%;
  color:white;
}
.app {
  width:100%;
  text-align: center;
}