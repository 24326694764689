@use '../../../styles/vars';
.navbar {
  z-index:7;
  position: fixed;
  top: 0;
  width: 320px; 
  height: 100%;
  margin-right: 0%;
  margin-left:0%;
  justify-content: space-between;
  align-items: center;
  background-color:  #1b235e;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
  &-items {
    //background-color: black;
    width:100%;
    height:80%;
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: auto;
    margin-right: auto;
    //width:320px;
    &-item {
      margin-bottom: 30px;
      &-link:link, &-link:visited, &-link:hover, &-link:active {
        font-size:x-large;
        //color:white;
        text-decoration: none;
        font-weight: bold;
        //text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
        background: linear-gradient(to top, #FBEB99 0%, #F48F00 100%);
        //text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        filter: drop-shadow(2px 2px #222222);
      }
    }
    &-item:hover {
      transform: scale(1.1);
    }
  }
}

@media (max-width: 640px) {
  .navbar {
    max-width: 30%;
    &-logo-img {
      width:110px;
    }
    &-items {
      &-item {
        margin-bottom: 30px;
        &-link:link, &-link:visited, &-link:hover, &-link:active {
          font-size:xx-small;
        }
      }
      &-item:hover {
        transform: scale(1.1);
      }
    }
  }
  
}