.home {
    background-color: inherit;
    color:black;
    width: 1600px;
    margin-left: 320px;
    height:100vh;
    &-title, &-text {
        text-decoration: none;
        font-weight: bold;
        background: linear-gradient(to top, #FBEB99 0%, #F48F00 100%);
        -webkit-text-fill-color: transparent;
        background-clip: text;
        filter: drop-shadow(1px 1px #222222);
    }
}
@media (max-width: 640px) {
    .home {
        width: 60%;
        margin-left: 120px;
        &-text {
            font-size: 50%;
            width:90%;
            margin:auto;
        }
        &-title {
            font-size: 70%;
            margin:auto;
        }
    }    
}