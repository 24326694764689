.examples {
    background-color: inherit;//rgb(180, 250, 255);
    color:black;
    width: 1600px;
    margin-left: 320px;
    height:100vh;
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    &-title, &-text {
        text-decoration: none;
        font-weight: bold;
        background: linear-gradient(to top, #FBEB99 0%, #F48F00 100%);
        -webkit-text-fill-color: transparent;
        background-clip: text;
        filter: drop-shadow(1px 1px #222222);
    }
    &-pre {
        color:white;
        background-color: black;
        padding: 10px;
        width:35%;
        margin-right: auto;
        margin-left: auto;
        text-align: start;
        &-code {
            background-color: black;
            color:white;
            display: flex;
            justify-content: flex-start;
            font-size: 80%;
        }
    }
}
#totopmarge {
    margin-top: 5%;
}
@media (max-width: 640px) {
    .examples {
        //max-width: 520px; 
        width:60%;
        //max-width: 640px; 
        margin-left: 120px;
        &-text {
            max-width: 320px; 
            font-size: 50%;
            width:90%;
            margin-left:auto;
            margin-right:auto;
        }
        &-title {
            font-size: 70%;
            margin-left:auto;
            margin-right:auto;
        }
        &-pre {
            color:white;
            background-color: black;
            padding: 10px;
            min-width: 90%;
            max-width: 100%;
            margin-right: auto;
            margin-left: auto;
            text-align: start;
            &-code {
                min-width:80%;
                max-width:100%;
                background-color: black;
                color:white;
                display: flex;
                justify-content: flex-start;
                font-size: 60%;
            }
        }
        &-img {
            max-width: 100%;
        }
    }    
}